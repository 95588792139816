<template>
  <div>
    <ReceptionAdhesionDepartement />
  </div>
</template>

<script>
import ReceptionAdhesionDepartement from '../../components/workflow/ReceptionAdhesionDepartement';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReceptionAdhesionDepartementalContainer',
  components: {
    ReceptionAdhesionDepartement,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      getUserCdpeId: 'getUserCdpeId',
    }),
  },
  created() {
    if (!this.isUserNational) {
      this.loadReceptionAdhesionDepartementLots({
        cdpeId: this.getUserCdpeId,
      });
    } else {
      this.loadFilterDepartements();
    }
  },

  methods: {
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
    ...mapActions([
      'loadReceptionAdhesionDepartementLots',
    ]),
  },
};
</script>
