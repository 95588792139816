<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="pa-5 mb-5">
            <v-card-title
              primary-title
              class="title"
            >
              Réception des adhésions départementales
            </v-card-title>
            <div v-if="isUserNational">
              <div>
                <v-alert
                  color="primary"
                  dense
                  type="info"
                  :value="!currentCdpeId"
                >
                  Veuillez sélectionner un <strong>Département</strong>
                </v-alert>
                <div class="text-right">
                  <v-autocomplete
                    :items="filterDepartements"
                    label="Département"
                    :item-text="item => `${item.code} - ${item.nom}`"
                    item-value="id"
                    @change="changeDepartement"
                  />
                </div>
              </div>
            </div>
            <div class="mb-5">
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  Montant départemental :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ totalDepartemental | currency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  Montant national :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ totalNational | currency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  Montant total à recevoir :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ amountToReceive | currency }}
                </v-col>
              </v-row>
            </div>
            <v-btn
              color="primary"
              :disabled="canNotBeReceived"
              @click="showConfirmationPopup = true"
            >
              Réceptionner
            </v-btn>
          </div>
        </v-card>
        <div>
          <v-data-table
            v-model="selected"
            light
            :headers="headers"
            :items="receptionAdhesionDepartementLots"
            item-key="id"
            show-select
            class="elevation-3"
            :loading="loading"
            loading-text="Chargement en cours"
            no-data-text="Aucun parents à valider"
            sort-by="cl.nom"
            :items-per-page="50"
            :footer-props="{
              itemsPerPageText: 'Lots par page:',
              itemsPerPageOptions: [20, 50, 100, 200]
            }"
          >
            <v-progress-linear
              v-if="loading"
              v-slot:progress
              color="blue"
            />

            <template v-slot:no-data>
              Aucun lot à réceptionner
            </template>

            <template v-slot:header.data-table-select>
              <v-checkbox
                :input-value="selectAllValue"
                :indeterminate="selectAllIndeterminate"
                @click.stop="toggleAll"
              />
            </template>

            <template v-slot:item.cl.nom="{ item }">
              {{ item.cl.nom }}
            </template>

            <template v-slot:item.totalParDestination.departemental="{ item }">
              {{ item.totalParDestination.departemental | currency }}
            </template>

            <template v-slot:item.totalParDestination.national="{ item }">
              {{ item.totalParDestination.national | currency }}
            </template>
          </v-data-table>

          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
      </v-col>
    </v-row>
    <ConfirmedModal
      v-if="showConfirmationPopup"
      :sentence="`Êtes-vous sûr de vouloir valider ces entrées et réceptionner un règlement de ${this.$options.filters.currency(amountToReceive)} de la parts des Conseils Locaux ?`"
      btn-action="Valider"
      @close="showConfirmationPopup = false"
      @confirmed="receiveLots()"
    />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import selectAllMixin from '../../mixins/vTableDataSelectAll';
import ConfirmedModal from '../reusableComponents/confirmedModal';

export default {
  name: 'ReceptionAdhesionDepartement',
  components: {
    ConfirmedModal,
  },
  mixins: [selectAllMixin],
  data: () => ({
    currentCdpeId: null,
    headers: [
      {
        text: 'Numéro de lot',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Conseil Principal',
        sortable: true,
        value: 'cl.nom',
      },
      {
        text: 'Montant départemental',
        sortable: false,
        value: 'totalParDestination.departemental',
      },
      {
        text: 'Montant national',
        sortable: false,
        value: 'totalParDestination.national',
      },
    ],
    showConfirmationPopup: false,
    showValidationLoader: false,
  }),
  computed: {
    ...mapGetters({
      receptionAdhesionDepartementLots: 'getReceptionAdhesionDepartementLots',
      loading: 'getWorkflowLoading',
    }),
    ...mapGetters('session', {
      getUserCdpeId: 'getUserCdpeId',
      isUserNational: 'isUserNational',
      isUserDepartemental: 'isUserDepartemental',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    items() {
      return this.receptionAdhesionDepartementLots;
    },

    totaux() {
      return (this.selected || []).reduce(
        (totaux, item) => ({
          local: totaux.local + item.totalParDestination.local,
          departemental: totaux.departemental + item.totalParDestination.departemental,
          national: totaux.national + item.totalParDestination.national,
        }),
        { local: 0, departemental: 0, national: 0 }
      );
    },
    totalLocal() {
      return this.totaux.local;
    },
    totalDepartemental() {
      return this.totaux.departemental;
    },
    totalNational() {
      return this.totaux.national;
    },
    canNotBeReceived() {
      return (this.selected || []).length === 0;
    },
    amountToReceive() {
      return this.totalNational + this.totalDepartemental;
    },
  },
  watch: {
    receptionAdhesionDepartementLots(val) {
      this.selected = val.slice();
    },
  },

  created() {
    if (this.isUserDepartemental) {
      this.currentCdpeId = this.getUserCdpeId;
    }
  },

  methods: {
    ...mapActions([
      'loadReceptionAdhesionDepartementLots',
      'receiveAdhesionDepartementLots',
    ]),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    changeDepartement(cdpeId) {
      this.currentCdpeId = cdpeId;
      this.loadReceptionAdhesionDepartementLots({
        cdpeId,
      });
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    receiveLots() {
      this.showConfirmationPopup = false;
      let lots = this.selected.map(lot => ({ id: lot.id }));
      this.receiveAdhesionDepartementLots({
        lots,
        cdpeId: this.currentCdpeId,
      }).then(() => {
        this.setSuccess({ info: 'La validation de la réception des conseils locaux a bien été prise en compte' });
      });
    },
  },
};
</script>
